import {
    Injectable,
    Injector,
    Compiler,
    Inject,
    NgModuleFactory,
    Type,
    ViewContainerRef,
} from "@angular/core";
import { InjectionToken } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { GenericPopupComponent } from "app/shared/components/generic-container/components/generic-popup/generic-popup.component";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Observable } from "rxjs";

export const LAZY_WIDGETS = new InjectionToken<{ [key: string]: string }>(
    "LAZY_WIDGETS"
);

@Injectable({
    providedIn: "root",
})
export class LazyLoaderService {
    constructor(
        private injector: Injector,
        private compiler: Compiler,
        @Inject(LAZY_WIDGETS)
        private lazyWidgets: {
            [key: string]: () => Promise<NgModuleFactory<any> | Type<any>>;
        },
        public dialogService: MatDialog
    ) {}

    async load(name: string, container: ViewContainerRef = null) {
        const tempModule = await this.lazyWidgets[name]();

        let moduleFactory;

        if (tempModule instanceof NgModuleFactory) {
            // For AOT
            moduleFactory = tempModule;
        } else {
            // For JIT
            // moduleFactory = await this.compiler.compileModuleAsync(tempModule);

            moduleFactory = await this.compiler.compileModuleAsync(tempModule);
        }

        const entryComponent = (moduleFactory.moduleType as any)[name];
        const moduleRef = moduleFactory.create(this.injector);

        const compFactory =
            moduleRef.componentFactoryResolver.resolveComponentFactory(
                entryComponent
            );

        if (!container)
            return {
                component: entryComponent,
                componentFactoryResolver: moduleRef.componentFactoryResolver,
            };

        const compRef = container.createComponent(compFactory);
        // compFactory.instance.ngOnChanges();
        return {
            component: compRef,
            componentFactoryResolver: compFactory,
        };
    }

    openPopup(data: any, config?: MatDialogConfig): Observable<any> {
        config = config ?? { width: "100vw", height: "100hv" };
        config.data = data;

        const dialogRef = this.dialogService.open(
            GenericPopupComponent,
            config
        );
        return dialogRef.beforeClosed();
    }
}
