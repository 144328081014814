import {
    CapacityKPIType,
    IgnoreTicketStatus,
    TicketLevelType,
} from "../app/core/models/enums";
import { IHorizon } from "app/core/models/interfaces";

export const environment = {
    production: true,
    hmr: false,
    apiBaseUrl: "./backend/api",
    pageSizeOptions: [50, 10, 100, 500],
    dataRefreshRate: 90000000,
    httpRequestTimeout: 600000,
    autoSaveInterval: <number>undefined,
    raiseErrorIfLogicalDeleted: "Y",
    queryLogicalDeletedFL: "N",
    errorIdentifier: "ORA-",
    fatalErrorIdentifier: "ORA-20999",
    defaultLanguage: "en",
    defaultLanguageInst: { languageCE: "en", languageLB: "English" },
    enFlag: "UK",
    appTitle: "Waves Flow Opt",
    attributePrompt: "Medium",
    docViewer: "google", // "google" | "office"
    capacityMonitorFilter: {
        ftmsCE: "FTMS0001",
        horizon: {
            shift: 0,
            month: 2,
            quarter: 0,
            hour: 0,
            day: 5,
            week: 4,
            addOverdueFL: true,
            decimalNB: 2,
        } as IHorizon,
        kpi: [CapacityKPIType.Percent],
        kpiValues: [
            "PERCENT",
            "AVAILABLE",
            "MARGIN",
            "SIM+PLAN",
            "PLANNED",
            "SIMULATED",
        ],
        ticketLevelType: [
            TicketLevelType.Family,
            TicketLevelType.Product,
            TicketLevelType.Qrm_Ticket,
        ],
        displayTicketLevelType: [
            TicketLevelType.Family,
            TicketLevelType.Product,
            TicketLevelType.Qrm_Ticket,
        ],
        ignoreStatuses: [],
        maximumSimulationNumber: 3,
        simExcludePhantomFL: false,
        withCapacityAwareness: false,
    },
    mctFilter: {
        mctMapTimeUnitCE: "day",
        mapType: {
            analysisFL: false,
            toBeFL: false,
            asIsFL: false,
        },
        cumulKpiPrecision: {
            mct: 1,
            ctt: 2,
            touchTM: 2,
        },
        ticketKpiPrecision: {
            mct: 2,
            ctt: 3,
            touchTM: 2,
        },
    },
    polca: {
        dataRefreshRate: 12000000,
    },
    noConnectionErrorMessage: "Cannot connect to the API.",
    expiredTokenErrorMessage: "Security token is expired. Please login again.",
    noConnectionErrorMessageClose: "Close",
};
