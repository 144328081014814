import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSearchBarModule, FuseShortcutsModule } from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";
import { ToolbarComponent } from "app/layout/components/toolbar/toolbar.component";
import { ToolbarService } from "./toolbar.service";
import { TranslateModule } from "app/shared/lib/translate/public_api";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { SignalRService } from "app/core/services/signalR-service/signalR-service";

@NgModule({
    declarations: [ToolbarComponent],
    imports: [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatTooltipModule,
        TranslateModule,
        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        MatSlideToggleModule,
    ],
    exports: [ToolbarComponent],
    providers: [ToolbarService, SignalRService],
})
export class ToolbarModule {}
