import { NgModule } from "@angular/core";

import { VerticalLayout1Module } from "app/layout/vertical/layout-1/layout-1.module";
import { VerticalLayout2Module } from "app/layout/vertical/layout-2/layout-2.module";
import { LayoutComponent } from "./layout.component";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";
import { VerticalLayout3Module } from "./vertical/layout-3/layout-3.module";
import { HorizontalLayout1Module } from "./horizontal/layout-1/layout-1.module";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { FuseModule } from "@fuse/fuse.module";
import { fuseConfig } from "app/fuse-config";
import { AuthenticationGuard } from "app/core/authentication/authentication.guard";
import { PopUpComponent } from "./pop-up/pop-up.component";
import { TranslateModule } from "app/shared/lib/translate/public_api";
import { EntityAuthenticationGuard } from "../core/authentication/entity-authentication.guard";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatIconModule } from "@angular/material/icon";
import { provideRoutes, RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "../main/home/home.component";
import { LazyLoaderService, LAZY_WIDGETS } from "./lazy-loading.service";
import { DialogService } from "primeng/dynamicdialog";

const lazyWidgets: Routes = [
    {
        path: "list",
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import(
                "app/shared/components/generic-container/generic-container.module"
            ).then((m) => m.GenericContainerModule),
    },
    {
        path: "edit",
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import(
                "app/shared/components/generic-container/generic-container.module"
            ).then((m) => m.GenericContainerModule),
    },
    {
        path: "procedure",
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import(
                "app/shared/components/generic-container/generic-container.module"
            ).then((m) => m.GenericContainerModule),
    },
    {
        path: "bomEdit",
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import(
                "app/main/product/qrm-product-bom/create-entity/qrm-product-bom-details.module"
            ).then((m) => m.QrmProductBomDetailsModule),
    },
    {
        path: "dynamicBomEdit",
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import(
                "app/main/product/qrm-product-bom/bom-tree/dynamic-bom-tree.module"
            ).then((m) => m.DynamicBomTreeModule),
    },
    {
        path: "uiOpenEdit",
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
            import(
                "app/main/product/qrm-product-bom/bom-tree/dynamic-bom-tree.module"
            ).then((m) => m.DynamicBomTreeModule),
    },
    {
        path: "mctMap",
        loadChildren: () =>
            import("app/main/mct-map/mct-map.module").then(
                (m) => m.MctMapModule
            ),
        canActivate: [AuthenticationGuard],
    },

    {
        path: "uiOpenMct",
        loadChildren: () =>
            import("app/main/mct-map/mct-map.module").then(
                (m) => m.MctMapModule
            ),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "uiOpenCalendar",
        loadChildren: () =>
            import("app/shared/components/calendar/calendar.module").then(
                (m) => m.CalendarModule
            ),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "entityNotes",
        loadChildren: () =>
            import(
                "app/shared/components/entity-notes/entity-notes.module"
            ).then((m) => m.EntityNotesModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "polca",
        loadChildren: () =>
            import("app/main/digital-polca/digital-polca.module").then(
                (m) => m.DigitalPolcaModule
            ),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "gantt",
        loadChildren: () =>
            import("app/main/gantt-calendar/gantt-calendar.module").then(
                (m) => m.GanttCalendarModule
            ),
        canActivate: [AuthenticationGuard],
    },
];

const routes: Routes = [
    {
        path: "login",
        loadChildren: () =>
            import("app/login/login.module").then((m) => m.LoginModule),
    },
    {
        path: "",
        component: LayoutComponent,
        canActivate: [AuthenticationGuard],
        children: [
            {
                path: "home",
                loadChildren: () =>
                    import("app/main/home/home.module").then(
                        (m) => m.HomeModule
                    ),
            },

            {
                path: "dashboard",
                loadChildren: () =>
                    import("app/main/dashboard/dashboard.module").then(
                        (m) => m.DashboardModule
                    ),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "sales-dashboard",
                loadChildren: () =>
                    import("app/main/dashboard/dashboard.module").then(
                        (m) => m.DashboardModule
                    ),
                canActivate: [AuthenticationGuard],
            },

            {
                path: "polca-dashboard",
                loadChildren: () =>
                    import("app/main/dashboard/dashboard.module").then(
                        (m) => m.DashboardModule
                    ),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "capacity-monitor",
                loadChildren: () =>
                    import(
                        "app/main/capacity-monitor/capacity-monitor.module"
                    ).then((m) => m.CapacityMonitorModule),
                canActivate: [AuthenticationGuard],
            },

            {
                path: "sales-capacity-monitor",
                loadChildren: () =>
                    import(
                        "app/main/capacity-monitor/capacity-monitor.module"
                    ).then((m) => m.CapacityMonitorModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "mct-map",
                loadChildren: () =>
                    import("app/main/mct-map/mct-map.module").then(
                        (m) => m.MctMapModule
                    ),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "digital-polca",
                loadChildren: () =>
                    import("app/main/digital-polca/digital-polca.module").then(
                        (m) => m.DigitalPolcaModule
                    ),
                canActivate: [EntityAuthenticationGuard],
                data: {
                    expectedId: "POLCA",
                },
            },
            {
                path: "gantt",
                loadChildren: () =>
                    import("app/main/gantt-calendar/gantt-calendar.module").then(
                        (m) => m.GanttCalendarModule
                    ),
                canActivate: [EntityAuthenticationGuard],
                data: {
                    expectedId: "POLCA",
                }
            },
            {
                path: "organizations",
                loadChildren: () =>
                    import(
                        "app/main/general-entities/organization/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [EntityAuthenticationGuard],
                data: {
                    expectedId: "ORG",
                },
            },
            {
                path: "organization",
                loadChildren: () =>
                    import(
                        "app/main/general-entities/organization/create-entity/organization-details.module"
                    ).then((m) => m.OrganizationDetailsModule),
                canActivate: [EntityAuthenticationGuard],
                data: {
                    expectedId: "ORG",
                },
            },
            {
                path: "languages",
                loadChildren: () =>
                    import(
                        "app/main/general-entities/language/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [EntityAuthenticationGuard],
                data: {
                    expectedId: "LANG",
                },
            },
            {
                path: "language",
                loadChildren: () =>
                    import(
                        "app/main/general-entities/language/create-entity/language-details.module"
                    ).then((m) => m.LanguageDetailsModule),
                canActivate: [EntityAuthenticationGuard],
                data: {
                    expectedId: "LANG",
                },
            },
            {
                path: "system-prefixes",
                loadChildren: () =>
                    import(
                        "app/main/general-entities/system-prefix/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [EntityAuthenticationGuard],
                data: {
                    expectedId: "SYSPR",
                },
            },
            {
                path: "system-prefix",
                loadChildren: () =>
                    import(
                        "app/main/general-entities/system-prefix/create-entity/system-prefix-details.module"
                    ).then((m) => m.SystemPrefixDetailsModule),
                canActivate: [EntityAuthenticationGuard],
                data: {
                    expectedId: "SYSPR",
                },
            },
            {
                path: "focused-trg-market-sgms",
                loadChildren: () =>
                    import(
                        "app/main/general-entities/focused-trg-market-sgm/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [EntityAuthenticationGuard],
                data: {
                    expectedId: "FTMS",
                },
            },
            {
                path: "focused-trg-market-sgm",
                loadChildren: () =>
                    import(
                        "app/main/general-entities/focused-trg-market-sgm/create-entity/focused-trg-market-sgm-details.module"
                    ).then((m) => m.FocusedTrgMarketSgmDetailsModule),
                canActivate: [EntityAuthenticationGuard],
                data: {
                    expectedId: "FTMS",
                },
            },
            {
                path: "visual-styles",
                loadChildren: () =>
                    import(
                        "app/main/general-entities/visual-style/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [EntityAuthenticationGuard],
                data: {
                    expectedId: "VSTL",
                },
            },
            {
                path: "visual-style",
                loadChildren: () =>
                    import(
                        "app/main/general-entities/visual-style/create-entity/visual-style-details.module"
                    ).then((m) => m.VisualStyleDetailsModule),
                canActivate: [EntityAuthenticationGuard],
                data: {
                    expectedId: "VSTL",
                },
            },
            {
                path: "units-of-measure",
                loadChildren: () =>
                    import(
                        "app/main/units-of-measure/unit-of-measure/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "unit-of-measure",
                loadChildren: () =>
                    import(
                        "app/main/units-of-measure/unit-of-measure/create-entity/unit-of-measure-details.module"
                    ).then((m) => m.UnitOfMeasureDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "units-of-measure-conversion",
                loadChildren: () =>
                    import(
                        "app/main/units-of-measure/unit-of-measure-conversion/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "unit-of-measure-conversion",
                loadChildren: () =>
                    import(
                        "app/main/units-of-measure/unit-of-measure-conversion/create-entity/unit-of-measure-conversion-details.module"
                    ).then((m) => m.UnitOfMeasureConversionDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "tickets",
                loadChildren: () =>
                    import(
                        "app/main/qrm-tickets/job-ticket/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "ticket",
                loadChildren: () =>
                    import(
                        "app/main/qrm-tickets/job-ticket/create-entity/job-ticket-details.module"
                    ).then((m) => m.JobTicketDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "ticket-activities",
                loadChildren: () =>
                    import(
                        "app/main/qrm-tickets/ticket-activity/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "ticket-activity",
                loadChildren: () =>
                    import(
                        "app/main/qrm-tickets/ticket-activity/create-entity/ticket-activity-details.module"
                    ).then((m) => m.TicketActivityDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "ticket-statuses",
                loadChildren: () =>
                    import(
                        "app/main/qrm-tickets/job-ticket-status/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [EntityAuthenticationGuard],
                data: {
                    expectedId: "JBTS",
                },
            },
            {
                path: "ticket-status",
                loadChildren: () =>
                    import(
                        "app/main/qrm-tickets/job-ticket-status/create-entity/job-ticket-status.module"
                    ).then((m) => m.QrmJobTicketStatusDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "calendars",
                loadChildren: () =>
                    import(
                        "app/main/calendar/calendar/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "calendar",
                loadChildren: () =>
                    import(
                        "app/main/calendar/calendar/create-entity/calendar-details.module"
                    ).then((m) => m.CalendarDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "calendar-non-work-days",
                loadChildren: () =>
                    import(
                        "app/main/calendar/calendar-non-work-day/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "calendar-non-work-day",
                loadChildren: () =>
                    import(
                        "app/main/calendar/calendar-non-work-day/create-entity/calendar-non-work-day-details.module"
                    ).then((m) => m.CalendarNonWorkDayDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "calendar-weekend-work-day",
                loadChildren: () =>
                    import(
                        "app/main/calendar/calendar-weekend-work-day/create-entity/calendar-weekend-work-day-details.module"
                    ).then((m) => m.CalendarWeekendWorkDayDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "calendar-work-shifts",
                loadChildren: () =>
                    import(
                        "app/main/calendar/calendar-work-shift/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "calendar-work-shift",
                loadChildren: () =>
                    import(
                        "app/main/calendar/calendar-work-shift/create-entity/calendar-work-shift-details.module"
                    ).then((m) => m.CalendarWorkShiftDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "calendar-work-shift-pattern",
                loadChildren: () =>
                    import(
                        "app/main/calendar/calendar-work-shift-pattern/create-entity/calendar-work-shift-pattern-details.module"
                    ).then((m) => m.CalendarWorkShiftPatternDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "calendar-work-shift-exceptions",
                loadChildren: () =>
                    import(
                        "app/main/calendar/calendar-work-shift-exception/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "calendar-work-shift-exception",
                loadChildren: () =>
                    import(
                        "app/main/calendar/calendar-work-shift-exception/create-entity/calendar-work-shift-exception-details.module"
                    ).then((m) => m.CalendarWorkShiftExceptionDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "qrm-cells",
                loadChildren: () =>
                    import(
                        "app/main/capacity/qrm-cell/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "qrm-cell",
                loadChildren: () =>
                    import(
                        "app/main/capacity/qrm-cell/create-entity/qrm-cell-details.module"
                    ).then((m) => m.QrmCellDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "capacity-clusters",
                loadChildren: () =>
                    import(
                        "app/main/capacity/capacity-cluster/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "capacity-cluster",
                loadChildren: () =>
                    import(
                        "app/main/capacity/capacity-cluster/create-entity/capacity-cluster-details.module"
                    ).then((m) => m.CapacityClusterDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "qrm-routing-capacities",
                loadChildren: () =>
                    import(
                        "app/main/capacity/qrm-routing-capacity/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "qrm-routing-capacity",
                loadChildren: () =>
                    import(
                        "app/main/capacity/qrm-routing-capacity/create-entity/qrm-routing-capacity-details.module"
                    ).then((m) => m.QrmRoutingCapacityDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "qrm-cell-routing-capacity",
                loadChildren: () =>
                    import(
                        "app/main/capacity/qrm-cell-routing-capacity/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "qrm-temp-capacity-deviations",
                loadChildren: () =>
                    import(
                        "app/main/capacity/qrm-cell-temp-capacity-deviation/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "qrm-temp-capacity-deviation",
                loadChildren: () =>
                    import(
                        "app/main/capacity/qrm-cell-temp-capacity-deviation/create-entity/qrm-cell-temp-capacity-deviation-details.module"
                    ).then((m) => m.QrmCellTempCapacityDeviationDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "qrm-products",
                loadChildren: () =>
                    import(
                        "app/main/product/qrm-product/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "qrm-product",
                loadChildren: () =>
                    import(
                        "app/main/product/qrm-product/create-entity/qrm-product-details.module"
                    ).then((m) => m.QrmProductDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "client-product",
                loadChildren: () =>
                    import(
                        "app/main/product/client-product/create-entity/qrm-product-details.module"
                    ).then((m) => m.ClientProductDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "qrm-product-families",
                loadChildren: () =>
                    import(
                        "app/main/product/qrm-product-family/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "qrm-product-family",
                loadChildren: () =>
                    import(
                        "app/main/product/qrm-product-family/create-entity/qrm-product-family-details.module"
                    ).then((m) => m.QrmProductFamilyDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "qrm-product-boms",
                loadChildren: () =>
                    import(
                        "app/main/product/qrm-product-bom/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "qrm-product-bom",
                loadChildren: () =>
                    import(
                        "app/main/product/qrm-product-bom/create-entity/qrm-product-bom-details.module"
                    ).then((m) => m.QrmProductBomDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "qrm-product-bom-part",
                loadChildren: () =>
                    import(
                        "app/main/product/qrm-product-bom-part/create-entity/qrm-product-bom-part-details.module"
                    ).then((m) => m.QrmProductBomPartDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "work-shifts",
                loadChildren: () =>
                    import(
                        "app/main/work-shift/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "work-shift",
                loadChildren: () =>
                    import(
                        "app/main/work-shift/create-entity/work-shift-details.module"
                    ).then((m) => m.WorkShiftDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "customers",
                loadChildren: () =>
                    import(
                        "app/main/customer-sales-order/customer/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "customer",
                loadChildren: () =>
                    import(
                        "app/main/customer-sales-order/customer/create-entity/customer-details.module"
                    ).then((m) => m.CustomerDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "sales-order-lines",
                loadChildren: () =>
                    import(
                        "app/main/customer-sales-order/sales-order-line/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "sales-orders",
                loadChildren: () =>
                    import(
                        "app/main/customer-sales-order/sales-order/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "sales-order",
                loadChildren: () =>
                    import(
                        "app/main/customer-sales-order/sales-order/create-entity/sales-order-details.module"
                    ).then((m) => m.SalesOrderDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "sales-order-line",
                loadChildren: () =>
                    import(
                        "app/main/customer-sales-order/sales-order-line/create-entity/sales-order-lines-details.module"
                    ).then((m) => m.SalesOrderLineDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "products",
                loadChildren: () =>
                    import(
                        "app/main/customer-sales-order/product/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "product",
                loadChildren: () =>
                    import(
                        "app/main/customer-sales-order/product/create-entity/product-details.module"
                    ).then((m) => m.ProductDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "projects",
                loadChildren: () =>
                    import(
                        "app/main/project/project/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "project",
                loadChildren: () =>
                    import(
                        "app/main/project/project/create-entity/project-details.module"
                    ).then((m) => m.ProjectDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "project-parts",
                loadChildren: () =>
                    import(
                        "app/main/project/project-part/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "project-budget-versions",
                loadChildren: () =>
                    import(
                        "app/main/project/project-budget-version/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "project-budget-version",
                loadChildren: () =>
                    import(
                        "app/main/project/project-budget-version/create-entity/project-budget-version-details.module"
                    ).then((m) => m.ProjectBudgetVersionDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "employees",
                loadChildren: () =>
                    import(
                        "app/main/project/employee/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "employee",
                loadChildren: () =>
                    import(
                        "app/main/project/employee/create-entity/employee-details.module"
                    ).then((m) => m.EmployeeDetailsModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "admin-panel",
                canActivate: [AuthenticationGuard],
                loadChildren: () =>
                    import(
                        "app/main/admin-panel/overview/overview.module"
                    ).then((m) => m.OverviewModule),
            },

            {
                path: "list",
                canActivate: [AuthenticationGuard],
                loadChildren: () =>
                    import(
                        "app/shared/components/generic-container/generic-container.module"
                    ).then((m) => m.GenericContainerModule),
            },
            {
                path: "edit",
                canActivate: [AuthenticationGuard],
                loadChildren: () =>
                    import(
                        "app/shared/components/generic-container/generic-container.module"
                    ).then((m) => m.GenericContainerModule),
            },
            // Fallback when no prior route is matched
            { path: "**", redirectTo: "/home", pathMatch: "full" },
        ],
    },
    {
        path: "connections",
        outlet: "modal",
        component: PopUpComponent,
        children: [
            {
                path: "tickets",
                loadChildren: () =>
                    import(
                        "app/main/qrm-tickets/job-ticket/list-entities/list-entities.module"
                    ).then((m) => m.ListEntitiesModule),
                canActivate: [AuthenticationGuard],
            },
            {
                path: "ticket",
                loadChildren: () =>
                    import(
                        "app/main/qrm-tickets/job-ticket/create-entity/job-ticket-details.module"
                    ).then((m) => m.JobTicketDetailsModule),
                canActivate: [AuthenticationGuard],
            },
        ],
    },
    {
        path: "qrm-bom-part",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/product/qrm-product-bom-part/create-entity/qrm-product-bom-part-details.module"
            ).then((m) => m.QrmProductBomPartDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "calendar-non-work-day",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/calendar/calendar-non-work-day/create-entity/calendar-non-work-day-details.module"
            ).then((m) => m.CalendarNonWorkDayDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "calendar-weekend-work-day",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/calendar/calendar-weekend-work-day/create-entity/calendar-weekend-work-day-details.module"
            ).then((m) => m.CalendarWeekendWorkDayDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "calendar-work-shifts",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/calendar/calendar-work-shift/list-entities/list-entities.module"
            ).then((m) => m.ListEntitiesModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "calendar-work-shift",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/calendar/calendar-work-shift/create-entity/calendar-work-shift-details.module"
            ).then((m) => m.CalendarWorkShiftDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "calendar-work-shift-pattern",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/calendar/calendar-work-shift-pattern/create-entity/calendar-work-shift-pattern-details.module"
            ).then((m) => m.CalendarWorkShiftPatternDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "calendar-work-shift-exception",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/calendar/calendar-work-shift-exception/create-entity/calendar-work-shift-exception-details.module"
            ).then((m) => m.CalendarWorkShiftExceptionDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "qrm-product",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/product/qrm-product/create-entity/qrm-product-details.module"
            ).then((m) => m.QrmProductDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "qrm-product-bom",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/product/qrm-product-bom/create-entity/qrm-product-bom-details.module"
            ).then((m) => m.QrmProductBomDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "qrm-product-bom-part",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/product/qrm-product-bom-part/create-entity/qrm-product-bom-part-details.module"
            ).then((m) => m.QrmProductBomPartDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "sales-order-lines",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/customer-sales-order/sales-order-line/list-entities/list-entities.module"
            ).then((m) => m.ListEntitiesModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "sales-order-line",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/customer-sales-order/sales-order-line/create-entity/sales-order-lines-details.module"
            ).then((m) => m.SalesOrderLineDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "sales-order",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/customer-sales-order/sales-order/create-entity/sales-order-details.module"
            ).then((m) => m.SalesOrderDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "ticket",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/qrm-tickets/job-ticket/create-entity/job-ticket-details.module"
            ).then((m) => m.JobTicketDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "project-part",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/project/project-part/create-entity/project-part-details.module"
            ).then((m) => m.ProjectPartFullDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "qrm-cell",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/capacity/qrm-cell/create-entity/qrm-cell-details.module"
            ).then((m) => m.QrmCellDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "customers",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/customer-sales-order/customer/list-entities/list-entities.module"
            ).then((m) => m.ListEntitiesModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "customer",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/customer-sales-order/customer/create-entity/customer-details.module"
            ).then((m) => m.CustomerDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "qrm-products",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/product/qrm-product/list-entities/list-entities.module"
            ).then((m) => m.ListEntitiesModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "products",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/customer-sales-order/product/list-entities/list-entities.module"
            ).then((m) => m.ListEntitiesModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "units-of-measure",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/units-of-measure/unit-of-measure/list-entities/list-entities.module"
            ).then((m) => m.ListEntitiesModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "unit-of-measure",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/units-of-measure/unit-of-measure/create-entity/unit-of-measure-details.module"
            ).then((m) => m.UnitOfMeasureDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "languages",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/general-entities/language/list-entities/list-entities.module"
            ).then((m) => m.ListEntitiesModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "language",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/general-entities/language/create-entity/language-details.module"
            ).then((m) => m.LanguageDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "projects",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/project/project/list-entities/list-entities.module"
            ).then((m) => m.ListEntitiesModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "project",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/project/project/create-entity/project-details.module"
            ).then((m) => m.ProjectDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "calendars",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/calendar/calendar/list-entities/list-entities.module"
            ).then((m) => m.ListEntitiesModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "calendar",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/calendar/calendar/create-entity/calendar-details.module"
            ).then((m) => m.CalendarDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "qrm-cells",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/capacity/qrm-cell/list-entities/list-entities.module"
            ).then((m) => m.ListEntitiesModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "tickets",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/qrm-tickets/job-ticket/list-entities/list-entities.module"
            ).then((m) => m.ListEntitiesModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "focused-trg-market-sgms",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/general-entities/focused-trg-market-sgm/list-entities/list-entities.module"
            ).then((m) => m.ListEntitiesModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "focused-trg-market-sgm",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/general-entities/focused-trg-market-sgm/create-entity/focused-trg-market-sgm-details.module"
            ).then((m) => m.FocusedTrgMarketSgmDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "visual-styles",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/general-entities/visual-style/list-entities/list-entities.module"
            ).then((m) => m.ListEntitiesModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "capacity-clusters",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/capacity/capacity-cluster/list-entities/list-entities.module"
            ).then((m) => m.ListEntitiesModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "capacity-cluster",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/capacity/capacity-cluster/create-entity/capacity-cluster-details.module"
            ).then((m) => m.CapacityClusterDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "qrm-product-boms",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/product/qrm-product-bom/list-entities/list-entities.module"
            ).then((m) => m.ListEntitiesModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "qrm-product-families",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/product/qrm-product-family/list-entities/list-entities.module"
            ).then((m) => m.ListEntitiesModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "qrm-product-family",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/product/qrm-product-family/create-entity/qrm-product-family-details.module"
            ).then((m) => m.QrmProductFamilyDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "visual-style",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/general-entities/visual-style/create-entity/visual-style-details.module"
            ).then((m) => m.VisualStyleDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "work-shift",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import(
                "app/main/work-shift/create-entity/work-shift-details.module"
            ).then((m) => m.WorkShiftDetailsModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: "mct-map",
        outlet: "modal",
        component: PopUpComponent,
        loadChildren: () =>
            import("app/main/mct-map/mct-map.module").then(
                (m) => m.MctMapModule
            ),
        canActivate: [AuthenticationGuard],
    },
];

export function lazyArrayToObj() {
    const result = {};
    for (const w of lazyWidgets) {
        result[w.path] = w.loadChildren;
    }
    return result;
}

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        VerticalLayout1Module,
        VerticalLayout2Module,
        VerticalLayout3Module,
        HorizontalLayout1Module,
        TranslateModule,

        // Material moment date module
        MatMomentDateModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // Material
        MatButtonModule,
        MatIconModule,
    ],
    exports: [
        VerticalLayout1Module,
        VerticalLayout2Module,
        VerticalLayout3Module,
    ],
    declarations: [LayoutComponent, PopUpComponent],
    providers: [
        AuthenticationGuard,
        EntityAuthenticationGuard,
        { provide: LAZY_WIDGETS, useFactory: lazyArrayToObj },
        DialogService,
        LazyLoaderService,
        provideRoutes(routes),
    ],
})
export class LayoutModule {}
