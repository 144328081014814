import { Component } from "@angular/core";
import { FuseNavHorizontalItemComponent } from "@fuse/components/navigation/horizontal/item/item.component";

@Component({
    selector: "nav-horizontal-item",
    templateUrl: "./item.component.html",
    styleUrls: ["./item.component.scss"]
})
export class NavHorizontalItemComponent extends FuseNavHorizontalItemComponent {

}
