import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { TranslateModule } from "app/shared/lib/translate/public_api";

import { NavigationComponent } from "./navigation.component";
import { NavVerticalItemComponent } from "./vertical/item/item.component";
import { NavVerticalCollapsableComponent } from "./vertical/collapsable/collapsable.component";
import { NavVerticalGroupComponent } from "./vertical/group/group.component";
import { NavHorizontalItemComponent } from "./horizontal/item/item.component";
import { NavHorizontalCollapsableComponent } from "./horizontal/collapsable/collapsable.component";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,

        MatIconModule,
        MatRippleModule,
        MatTooltipModule,

        TranslateModule,
    ],
    exports: [NavigationComponent],
    declarations: [
        NavigationComponent,
        NavVerticalGroupComponent,
        NavVerticalItemComponent,
        NavVerticalCollapsableComponent,
        NavHorizontalItemComponent,
        NavHorizontalCollapsableComponent,
    ],
})
export class FuseNavigationModule {}
