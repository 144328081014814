import {
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    AfterViewInit,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
} from "@angular/core";
import { Subject } from "rxjs";
import { finalize, map, takeUntil, tap } from "rxjs/operators";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";

import { navigation } from "app/navigation/navigation";
import { ToolbarService } from "./toolbar.service";
import { AuthenticationService } from "app/core/authentication/authentication.service";
import { CurrentUser } from "app/core/models/CurrentUser";
import { AppTranslationService, ErrorHandlerService } from "app/core/services";
import { Language } from "app/core/models/entity-models";
import { MediaObserver } from "@angular/flex-layout";
import { FuseMatchMediaService } from "@fuse/services/match-media.service";
import { AuthService } from "@auth0/auth0-angular";
import { SignalRService } from "app/core/services/signalR-service/signalR-service";

@Component({
    selector: "toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: ["./toolbar.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent implements OnInit, AfterViewInit, OnDestroy {
    public horizontalNavbar: boolean;
    public rightNavbar: boolean;
    public hiddenNavbar: boolean;
    public languages: Array<Language>;
    public selectedLanguage: string;
    public navigation: any;
    public currentUser: CurrentUser;

    public listStyle = false;
    public navbarFolded = false;
    public navbarOpened = true;

    // Private
    private _unsubscribeAll: Subject<any>;
    title: string;
    icon: string;

    signalRConnectionStatus: boolean;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {AppTranslationService} _translateService
     */
    constructor(
        private readonly fuseConfigService: FuseConfigService,
        private readonly fuseSidebarService: FuseSidebarService,
        private readonly translationService: AppTranslationService,
        public readonly toolbarService: ToolbarService,
        private readonly authenticationService: AuthenticationService,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly changeDetectionRef: ChangeDetectorRef,
        private readonly observableMedia: MediaObserver,
        private readonly fuseMatchMediaService: FuseMatchMediaService,
        public auth: AuthService,

        private signalRService: SignalRService
    ) {
        this.languages = this.translationService.languages;

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.currentUser = this.authenticationService.currentUser;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    public ngOnInit(): void {
        this.signalRService.initializeSignalRConnection();
        this.signalRService.conectionNotification
            .pipe(
                tap((connectionStatus) => {
                    this.signalRConnectionStatus = connectionStatus;
                    this.changeDetectionRef.detectChanges();
                })
            )
            .subscribe();
        // Subscribe to the config changes
        this.fuseConfigService.config
            .pipe(
                takeUntil(this._unsubscribeAll),
                map((settings: any) => {
                    this.horizontalNavbar =
                        settings.layout.navbar.position === "top";
                    this.rightNavbar =
                        settings.layout.navbar.position === "right";
                    this.hiddenNavbar = settings.layout.navbar.hidden === true;
                    this.changeDetectionRef.detectChanges();
                    return settings;
                })
            )
            .subscribe();

        // Set the selected language from default languages
        this.translationService.currentLanguage
            .pipe(
                takeUntil(this._unsubscribeAll),
                map((_) => {
                    this.selectedLanguage = _;
                    this.changeDetectionRef.detectChanges();
                    return _;
                })
            )
            .subscribe();

        this.fuseMatchMediaService.onMediaChange
            .pipe(
                takeUntil(this._unsubscribeAll),
                map((mediaChange) => {
                    const isActive = this.observableMedia.isActive("lg");
                    if (isActive) {
                        setTimeout(() => {
                            this.fuseSidebarService.getSidebar("navbar").fold();
                        }, 0);
                    }
                    this.changeDetectionRef.detectChanges();
                    return mediaChange;
                })
            )
            .subscribe();

        this.toolbarService.titleChanged
            .pipe(
                takeUntil(this._unsubscribeAll),
                map((_) => {
                    if (_) {
                        this.title = _;
                        this.changeDetectionRef.detectChanges();
                    }
                    return _;
                })
            )
            .subscribe();

        this.toolbarService.iconChanged
            .pipe(
                takeUntil(this._unsubscribeAll),
                map((_) => {
                    if (_) {
                        this.icon = _;
                        this.changeDetectionRef.detectChanges();
                    }
                    return _;
                })
            )
            .subscribe();
    }

    /**After View init */
    public ngAfterViewInit() {
        this.navbarFolded = this.fuseSidebarService.getSidebar("navbar").folded;
        this.fuseSidebarService
            .getSidebar("navbar")
            .foldedChanged.asObservable()
            .pipe(
                takeUntil(this._unsubscribeAll),
                map((_) => {
                    this.navbarFolded = _;
                    this.changeDetectionRef.detectChanges();
                    return _;
                })
            )
            .subscribe();

        this.navbarOpened = this.fuseSidebarService.getSidebar("navbar").opened;
        this.changeDetectionRef.detectChanges();
        this.fuseSidebarService
            .getSidebar("navbar")
            .openedChanged.asObservable()
            .pipe(
                takeUntil(this._unsubscribeAll),
                map((_) => {
                    this.navbarOpened = _;
                    this.changeDetectionRef.detectChanges();
                    return _;
                })
            )
            .subscribe();
    }

    /**
     * On destroy
     */
    public ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next({});
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    public toggleSidebarOpen(key): void {
        this.fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Toggle sidebar fold or open
     *
     * @param key
     */
    public toggleSidebarFoldOpen(key): void {
        if (this.fuseSidebarService.getSidebar(key).folded)
            this.fuseSidebarService.getSidebar(key).toggleFold();
        else this.toggleSidebarOpen(key);
    }

    /**
     * Search
     *
     * @param value
     */
    public search(value): void {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    public setLanguage(lang: Language): void {
        this.translationService.setCurrentLanguage(lang.languageCE);
    }

    public onLanguageClick(): void {
        this.languages = this.translationService.languages;
    }

    public getFlagLocation(languageCE: string): string {
        return this.translationService.getLanguageFlag(languageCE);
    }

    public changeStyle() {
        this.toolbarService.onStyleChanged.next(this.listStyle);
    }

    public logout() {
        let url = document.location.origin;
        this.auth.logout({
            async openUrl(url) {
                window.location.replace(url);
            },
        });
        // this.authenticationService.logout().subscribe(
        //     () => {},
        //     (error) => {
        //         this.errorHandlerService.error(error);
        //     }
        // );
    }
}
