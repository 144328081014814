import { Component } from "@angular/core";

import { fuseAnimations } from "@fuse/animations";
import { FuseNavHorizontalCollapsableComponent } from "@fuse/components/navigation/horizontal/collapsable/collapsable.component";

@Component({
    selector   : "nav-horizontal-collapsable",
    templateUrl: "./collapsable.component.html",
    styleUrls  : ["./collapsable.component.scss"],
    animations : fuseAnimations
})
export class NavHorizontalCollapsableComponent extends FuseNavHorizontalCollapsableComponent
{
    
}
