import {
    ChangeDetectorRef,
    Component,
    HostBinding,
    Input,
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil, filter } from "rxjs/operators";

import { FuseNavigationItem } from "@fuse/types";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import {
    ActivatedRoute,
    Router,
    NavigationStart,
    NavigationEnd,
} from "@angular/router";
import { FuseNavVerticalItemComponent } from "@fuse/components/navigation/vertical/item/item.component";
import { NavigationItem } from "app/layout/types/fuse-navigation";

@Component({
    selector: "nav-vertical-item",
    templateUrl: "./item.component.html",
    styleUrls: ["./item.component.scss"],
})
export class NavVerticalItemComponent extends FuseNavVerticalItemComponent {
    @HostBinding("class")
    public classes = "nav-item";

    public selectedId: string;
    public hoveredId: string;

    @Input()
    public item: NavigationItem;

    // Private
    private readonly unsubscribeAll: Subject<any>;

    /**
     * Constructor
     */

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        changeDetectorRef: ChangeDetectorRef,
        fuseNavigationService: FuseNavigationService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router
    ) {
        super(changeDetectorRef, fuseNavigationService);

        // Set the private defaults
        this.unsubscribeAll = new Subject();
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.activatedRoute.params
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => {
                this.selectedId =
                    this.activatedRoute.snapshot.firstChild.firstChild.params.qrmCellCE;

                const url = `/${this.activatedRoute.snapshot.firstChild.url
                    .map((u) => u.path)
                    .join("/")}`;

                this.setActiveForOtherRoute(url);
            });

        this.router.events
            .pipe(filter((_) => _ instanceof NavigationStart))
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((_: NavigationStart) => {
                const url = _.url;
                if (url.includes("/digital-polca/")) {
                    const a = url.split("/");
                    this.selectedId = a[2];
                } else {
                    this.selectedId = undefined;
                }
            });

        this.router.events
            .pipe(filter((_) => _ instanceof NavigationEnd))
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((_: NavigationEnd) => {
                this.setActiveForOtherRoute(_.url);
            });
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        // Unsubscribe from all subscriptions
        this.unsubscribeAll.next({});
        this.unsubscribeAll.complete();
    }

    public onMouseEnter(evt: MouseEvent, item: FuseNavigationItem) {
        this.hoveredId = item.id;
    }

    public onMouseLeave(evt: MouseEvent, item: FuseNavigationItem) {
        this.hoveredId = undefined;
    }

    private setActiveForOtherRoute(currentLink: string) {
        if (!this.item.routerLinkActiveFor) {
            return;
        }

        const link = currentLink
            .split("/")
            .filter((s) => s)[0]
            .split("?")[0];
        const itemUrl = this.item.url.split("/").filter((s) => s)[0];

        if (!link || link === itemUrl) {
            return;
        }

        const isActiveForOtherRoute =
            this.item.routerLinkActiveFor.find(
                (route) => link === route.split("/").filter((s) => s)[0]
            ) !== undefined;

        if (isActiveForOtherRoute) {
            this.item.classes = `${this.item.classes || ""}${
                this.item.classes && this.item.classes.length > 0 ? ", " : ""
            }active accent`;
        } else if (this.item.classes) {
            this.item.classes = this.item.classes
                .split(" ")
                .filter((s) => s.trim() !== "active" && s.trim() !== "accent")
                .join(" ");
        }
    }
}
