import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";
import { delay, filter, take, takeUntil } from "rxjs/operators";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FusePerfectScrollbarDirective } from "@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseMatchMediaService } from "@fuse/services/match-media.service";
import { AuthenticationService } from "../../../../../core/authentication/authentication.service";

@Component({
    selector: "navbar-vertical-style-2",
    templateUrl: "./style-2.component.html",
    styleUrls: ["./style-2.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class NavbarVerticalStyle2Component implements OnInit, OnDestroy {
    public fuseConfig: any;
    public navigation: any;

    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Router} _router
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private readonly fuseMatchMediaService: FuseMatchMediaService,
        private readonly authenticationService: AuthenticationService,
        private _router: Router
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(FusePerfectScrollbarDirective, { static: true })
    public set directive(theDirective: FusePerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._fuseNavigationService.onItemCollapseToggled
            .pipe(delay(500), takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                //this._fusePerfectScrollbar.update();

                setTimeout(() => {
                    const activeNavItem: any = document.querySelector(
                        "navbar .nav-link.active"
                    );

                    if (activeNavItem) {
                        const activeItemOffsetTop = activeNavItem.offsetTop,
                            activeItemOffsetParentTop =
                                activeNavItem.offsetParent
                                    ? activeNavItem.offsetParent.offsetTop
                                    : undefined,
                            scrollDistance =
                                activeItemOffsetTop -
                                activeItemOffsetParentTop -
                                48 * 3;

                        if (!isNaN(scrollDistance)) {
                            this._fusePerfectScrollbar.scrollToTop(
                                scrollDistance
                            );
                        }
                    }
                }, 0);
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                setTimeout(() => {
                    const activeNavItem: any = document.querySelector(
                        "navbar .nav-link.active"
                    );

                    if (activeNavItem) {
                        const activeItemOffsetTop = activeNavItem.offsetTop,
                            activeItemOffsetParentTop =
                                activeNavItem.offsetParent
                                    ? activeNavItem.offsetParent.offsetTop
                                    : undefined,
                            scrollDistance =
                                activeItemOffsetTop -
                                activeItemOffsetParentTop -
                                48 * 3;

                        this._fusePerfectScrollbar.scrollToTop(scrollDistance);
                    }
                });
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    public ngOnInit(): void {
        if (this.authenticationService.currentUser.user.environmentLB)
            this.logoText = this.authenticationService.currentUser.user.environmentLB;
        else if (this.authenticationService.currentUser.user.environmentCE)
            this.logoText = this.authenticationService.currentUser.user.environmentCE;
        else this.logoText = "FlowOpt";
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                if (this._fuseSidebarService.getSidebar("navbar")) {
                    this._fuseSidebarService.getSidebar("navbar").close();
                }
            });

        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(
                filter((value) => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation =
                    this._fuseNavigationService.getCurrentNavigation();
            });

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        this.fuseMatchMediaService.onMediaChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((breakpoint) => {
                const navbar = this._fuseSidebarService.getSidebar("navbar");

                if (!navbar) {
                    return;
                }
                if (breakpoint === "lg" && navbar.foldedAutoTriggerOnHover) {
                    navbar.fold();
                } else if (
                    this.fuseConfig &&
                    !this.fuseConfig.layout.navbar.folded &&
                    navbar.folded &&
                    navbar.foldedAutoTriggerOnHover
                ) {
                    navbar.unfold();
                }
            });
    }
    @Input()
    public logoText: string = "Flow";
    /**
     * On destroy
     */
    public ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next({});
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    public toggleSidebarOpened(): void {
        this._fuseSidebarService.getSidebar("navbar").toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    public toggleSidebarFolded(): void {
        this._fuseSidebarService.getSidebar("navbar").toggleFold();
    }

    /**
     * Handle Mouse click event on logo
     * @param evt
     */
    public onLogoClick(evt: MouseEvent): void {
        this._router.navigate(["/home"]);
    }
}
