<h1
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
>
    {{ data.title | translate }}
    <span fxFlex></span>
    <button
        class="top-close"
        mat-icon-button
        (click)="closeDialog($event, false)"
    >
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div mat-dialog-content style="width: 100%">
    <ng-container #popupContainer></ng-container>
    <h3 *ngIf="data.text?.length > 0" class="pl-20 pr-20" [innerHtml]="data.text | translate: data.params"></h3>
    <div class="pl-20 pr-20">
        <mat-checkbox *ngIf="data.error"
                      class="example-margin"
                      [(ngModel)]="showDetails">Error Details</mat-checkbox>
        <div *ngIf="showDetails">
            <json2-tree [data]="data.error"></json2-tree>
        </div>
        <div *ngIf="showMessage">{{theMessage}}</div>
    </div>
</div>

<div class="buttons">
    <button *ngIf="!showMessage" class="close" mat-button (click)="closeDialog($event, false)">
        {{ "DIALOG.CANCEL" | translate }}
    </button>
    <button *ngIf="data.showConfirmBtn !== false"
        class="save"
        [disabled]="disableSave"
        mat-button
        (click)="closeDialog($event, true)"
    >
        {{ "DIALOG.CONFIRM" | translate }}
    </button>
</div>
